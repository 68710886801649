/**
 * @author: Jok
 * @date: 2022-10-27 09:23
 * @explain: VUE ROUTER file.
 * @updateBy: Jok-2023/01/30-add creator infomation and some explain text.
 * @updateBy: Jok-2023/09/01-路由跳转增加提示判断。
 */

import Vue from "vue";
import VueRouter from "vue-router";
import config from "@/config/config";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "主页",
    component: () => import("@/views/index"),
    redirect: "/dataStatistics",
    children: [
      {
        path: "/dataStatistics",
        name: "数据统计",
        component: () => import("@/views/conf/dataStatistics"),
      },
      {
        path: "/accountMgmt",
        name: "权限管理",
        component: () => import("@/views/rightManage/accountMgmt"),
      },
      {
        path: "/rolesMgmt",
        name: "角色管理",
        component: () => import("@/views/rightManage/rolesMgmt"),
      },
      {
        path: "/rolesMgmt/roleInsert",
        name: "新增角色",
        component: () => import("@/views/rightManage/roleInsert"),
      },
      {
        path: "/rolesMgmt/assignRole",
        name: "分配角色",
        component: () => import("@/views/rightManage/assignRole"),
      },
      {
        path: "/rolesMgmt/assignRole",
        name: "分配角色",
        component: () => import("@/views/rightManage/assignRole"),
      },
      {
        path: "/rolesMgmt",
        name: "角色管理",
        component: () => import("@/views/rightManage/rolesMgmt"),
      },
      {
        path: "/routeMgmt",
        name: "路由管理",
        component: () => import("@/views/rightManage/routeMgmt"),
      },
      {
        path: "/routeMgmt/routeInsert",
        name: "路由新增",
        component: () => import("@/views/rightManage/routeInsert"),
      },
      {
        path: "/userMgmt",
        name: "客户管理",
        component: () => import("@/views/rightManage/userMgmt"),
      },
      {
        path: "/userMgmt/assignUserRole",
        name: "用户角色",
        component: () => import("@/views/rightManage/assignUserRole"),
      },
      {
        path: "/actvtReview",
        name: "老友研学季",
        component: () => import("@/views/actvtManage/actvtReview.vue"),
      },
      {
        path: "/actvtReviewTravel",
        name: "欣颐旅居",
        component: () => import("@/views/actvtManage/actvtReviewTravel.vue"),
      },
      {
        path: "/actvtReviewOlder",
        name: "居家助老",
        component: () => import("@/views/actvtManage/actvtReviewOlder.vue"),
      },
      {
        path: "/actvtReviewWelfare",
        name: "精选福利",
        component: () => import("@/views/actvtManage/actvtReviewWelfare.vue"),
      },
      {
        path: "/actvtPublish/actvtInsert",
        name: "新增老友研学季",
        component: () => import("@/views/actvtManage/actvtInsert.vue"),
      },
      {
        path: "/actvtPublish/actvtInsertTravel",
        name: "新增欣颐旅居",
        component: () => import("@/views/actvtManage/actvtInsertTravel.vue"),
      },
      {
        path: "/actvtPublish/actvtInsertOlder",
        name: "新增居家助老",
        component: () => import("@/views/actvtManage/actvtInsertOlder.vue"),
      },
      {
        path: "/actvtPublish/actvtInsertWelfare",
        name: "新增精选福利",
        component: () => import("@/views/actvtManage/actvtInsertWelfare.vue"),
      },
      {
        path: "/xinyiLesson",
        name: "欣颐课堂",
        component: () => import("@/views/actvtManage/xinyiLesson.vue"),
      },
      {
        path: "/xinyiLesson/lessonInsert",
        name: "新增欣颐课堂",
        component: () => import("@/views/actvtManage/lessonInsert.vue"),
      },
      {
        path: "/characterStyle",
        name: "人物风采",
        component: () => import("@/views/actvtManage/characterStyle.vue"),
      },
      {
        path: "/characterStyle/characterInsert",
        name: "新增人物风采",
        component: () => import("@/views/actvtManage/characterInsert.vue"),
      },
      {
        path: "/partnerReview/partnerInsert",
        name: "机构入驻",
        component: () => import("@/views/partnerManage/partnerInsert.vue"),
      },
      {
        path: "/partnerReview/partnerExtraInsert",
        name: "添加配套信息",
        component: () => import("@/views/partnerManage/partnerExtraInsert.vue"),
      },
      {
        path: "/staffMgmt",
        name: "入驻管理",
        component: () => import("@/views/partnerManage/staffMgmt.vue"),
      },
      {
        path: "/staffMgmt/staffInsert",
        name: "添加人员",
        component: () => import("@/views/partnerManage/staffInsert.vue"),
      },
      {
        path: "/partnerMgmt",
        name: "机构管理",
        component: () => import("@/views/partnerManage/partnerMgmt.vue"),
      },
      {
        path: "/partnerOrder",
        name: "机构预约",
        component: () => import("@/views/partnerManage/partnerOrder.vue"),
      },
      {
        path: "/partnerComment",
        name: "机构评论",
        component: () => import("@/views/partnerManage/partnerComment.vue"),
      },
      {
        path: "/actvtCcpage",
        name: "图文管理",
        component: () => import("@/views/actvtManage/actvtCcpage.vue"),
      },
      {
        path: "/ccpageInsert",
        name: "新建图文",
        component: () => import("@/views/actvtManage/ccpageInsert.vue"),
      },
      {
        path: "/actvtCcpageDetail",
        name: "图文详情",
        component: () => import("@/views/actvtManage/actvtCcpageDetail.vue"),
      },
      {
        path: "/advertisementConf",
        name: "广告管理",
        component: () => import("@/views/conf/advertisementConf.vue"),
      },
      {
        path: "/advertisementConf/advertisementInsert",
        name: "新增广告",
        component: () => import("@/views/conf/advertisementInsert.vue"),
      },
      {
        path: "/iconConf",
        name: "图标管理",
        component: () => import("@/views/conf/iconConf.vue"),
      },
      {
        path: "/iconConf/iconInsert",
        name: "新增图标",
        component: () => import("@/views/conf/iconInsert.vue"),
      },
      {
        path: "/ptnrMatingConf",
        name: "配套配置",
        component: () => import("@/views/conf/ptnrMatingConf.vue"),
      },
      {
        path: "/ptnrMatingConf/ptnrMatingInsert",
        name: "添加配套",
        component: () => import("@/views/conf/ptnrMatingInsert.vue"),
      },
      {
        path: "/ptnrMatingConf",
        name: "配套配置",
        component: () => import("@/views/conf/ptnrMatingConf.vue"),
      },
      {
        path: "/olderDesign",
        name: "适老设计",
        component: () => import("@/views/actvtManage/olderDesign.vue"),
      },
      {
        path: "/olderDesign/olderDesignInser",
        name: "新增设计",
        component: () => import("@/views/actvtManage/olderDesignInser.vue"),
      },
      {
        path: "/policy",
        name: "政策咨询",
        component: () => import("@/views/actvtManage/policy.vue"),
      },
      {
        path: "/policy/policyInsert",
        name: "新增政策咨询",
        component: () => import("@/views/actvtManage/policyInsert.vue"),
      },
      {
        path: "/health",
        name: "养生建议",
        component: () => import("@/views/actvtManage/health.vue"),
      },
      {
        path: "/health/healthInsert",
        name: "新增养身建议",
        component: () => import("@/views/actvtManage/healthInsert.vue"),
      },
      {
        path: "/goods",
        name: "心选好物",
        component: () => import("@/views/actvtManage/goods.vue"),
      },
      {
        path: "/goods/goodsInsert",
        name: "新增心选好物",
        component: () => import("@/views/actvtManage/goodsInsert.vue"),
      },
      {
        path: "/ccpage",
        name: "长页管理",
        component: () => import("@/views/fileManage/ccpage.vue"),
      },
      {
        path: "/ccpage/ccpageInsert",
        name: "新增长页",
        component: () => import("@/views/fileManage/ccpageInsert.vue"),
      },
      {
        path: "/richText",
        name: "图文管理",
        component: () => import("@/views/fileManage/richText.vue"),
      },
      {
        path: "/commentReview",
        name: "评论审核",
        component: () => import("@/views/partnerManage/commentReview.vue"),
      },
      {
        path: "/staffOrder",
        name: "名医预约",
        component: () => import("@/views/partnerManage/staffOrder.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "后台账户登录",
    component: () => import("../views/login/login.vue"),
  },
];

// vue-router-v3.1.0原生方法返回promise,不捕获异常控制台会报错,
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  // mode: "history",
  mode: "hash",
  // base: process.env.BASE_URL,
  base: "/oam",
  routes,
});

router.beforeEach((to, from, next) => {
  //我在这里模仿了一个获取用户信息的方法
  if (localStorage.getItem("userInfo")) {
    //如果用户信息存在则往下执行
    next();
  } else {
    //如果用户token不存在则跳转到login页面
    if (to.path === "/login") {
      next();
    } else {
      next("/login");
    }
  }
});

export default router;
