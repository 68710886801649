/**
 * @author: Jok
 * @date: 2022-10-27 09:23
 * @explain: a js file which include some common js function.
 */
/**
 * @updateAuthor: Jok
 * @updateDate: 2023-01-30 17:00
 * @updateExplain: add creator infomation and some explain text.
 */

const dayjs = require("dayjs");
// 嵌套数组拍平
function arrayToFlat(arr, key) {
  return arr.reduce((res, element) => {
    return res.concat(
      element,
      Array.isArray(element[key]) ? arrayToFlat(element[key], key) : []
    );
  }, []);
}
function getLocalStorage(key) {
  return JSON.parse(localStorage.getItem(key));
}
function setLocalStorage(key, value) {
  let str = JSON.stringify(value);
  localStorage.setItem(key, str);
}

// 获取两个数组差异
function getArrayDifference(arr1, arr2) {
  return arr1.concat(arr2).filter(function (v, i, arr) {
    return arr.indexOf(v) === arr.lastIndexOf(v);
  });
}

// 防抖函数  有问题 不生效
function debounce(fn, delay) {
  //设置time为定时器
  var time = null;
  //闭包原理，返回一个函数
  return function (e) {
    //如果定时器存在则清空定时器
    if (time) {
      clearTimeout(time);
    }
    //设置定时器，规定时间后执行真实要执行的函数
    time = setTimeout(() => {
      //此箭头函数里的this指向btn这个按钮
      fn.call(this, arguments); //改变真实要执行函数的this指向，原submit函数里面的this指向window
    }, delay);
  };
}

//根据生日计算年龄
function getAgeByBirth(birth) {
  // console.log(birth);
  if (birth) {
    const nowTime = dayjs(new Date()).format("YYYY-MM-DD");
    const birthday = dayjs(birth).format("YYYY-MM-DD");
    return dayjs(nowTime).diff(birthday, "years");
  } else {
    return null;
  }
}

/** 给返回富文本字符串添加样式 */
function processRichTextStyle(richText) {
  if (!richText) {
    return "";
  }
  var responseRichText = richText;
  // 富文本编辑器中图片添加类名（为了大小显示正常）
  responseRichText = responseRichText.replace(
    /<img/gi,
    '<img class="richText-img"'
  );
  // 富文本编辑器中iframe转换video
  responseRichText = responseRichText
    .replaceAll(
      "<iframe",
      `<video style="width:100%;outline:none;" controls="" autoplay=""`
    )
    .replaceAll("</iframe>", "</video>");
  return responseRichText;
}

module.exports = {
  arrayToFlat,
  getArrayDifference,
  getLocalStorage,
  setLocalStorage,
  debounce,
  getAgeByBirth,
  processRichTextStyle,
};
