/**
 * @author: Jok
 * @date: 2022-10-27 09:23
 * @explain: global variable name configuration.
 */
/**
 * @updateAuthor: Jok
 * @updateDate: 2023-01-30 17:00
 * @updateExplain: add creator infomation and some explain text.
 */
module.exports = {
  INSERT_PARTNER: "机构入驻",
  INSERT_FRANZ: "加盟店入驻",
  INSERT_CORP: "企业入驻",
  INSERT_ACCOUNT: "添加用户",
  ACCOUNT_SEARCH_PH: "请输入账号名进行搜索",
  ROLE_SEARCH_PH: "请输入角色ID进行搜索",
  ROUTE_SEARCH_PH: "请输入权限名称关键字进行搜索",
  CERT_SEARCH_PH: "请根据用户昵称或编号进行搜索",
  DELETE_BTN: "删除",
  EDIT_BTN: "编辑",
  EDIT_BTN_CONTINUE: "继续编辑",
  CHECK_BTN: "查看",
  STATUS_PASSED: "已通过",
  STATUS_REJECTED: "已拒绝",
  STATUS_LOCALSAVE: "临时保存",
  STATUS_PENDING: "待审核",
  PASS_BTN: "同意",
  REJECT_BTN: "拒绝",
  INSERT_PLATE_ROLE: "添加平台角色",
  INSERT_ROUTE_RIGHT: "添加路由权限",
  ASSIGN_RIGHT_BTN: "分配权限",
  ASSIGN_ROLE_BTN: "分配角色",
  ADD_ROLE_BTN: "添加角色",
  INSERT_STAFF: "添加人员",
  ADD_STRUCT: "添加架构",
  INSERT_ACTVT: "发布活动",
  INSERT_FRANZ_CVC: "添加加盟店卡券",
  SEARCH_BTN: "查询",
  BAN_ACCOUNT: "封禁",
  CANCEL: "取消",
  VIEW_REPORT: "查看举报信息",
  SURE_BAN: "确定封禁",
  CHECK_FEEDBACK_DETAIL: "查看反馈详情",
  PUBLISH_INTERNAL_MESSAGE: "发送消息",
  CHECK_INTERNAL_MESSAGE: "查看站内消息",
  INSERT_TAST_BTN: "新增任务",
  BATCH_EXPORT_EXCEL: "批量导出EXCEL",
  INSERT_CCPAGE: "新增图文",
  BIND_CCPAGE: "设置长页",
  INSERT_PACKAGE: "新增套餐",
  SET_SERVICE: "设置服务",
  INSERT_SERVICE: "新增服务",
  SET_PERMISSION: "设置权限",
  INSERT_PERMISSION: "新增权限",
  INSERT_LESSON: "新增欣颐课堂",
  LESSON_SEARCH_PH: "请输入课堂类型关键字进行搜索",
  INSERT_CHARACTER: "新增人物风采",
  CHARACTER_SEARCH_PH: "请输入人物风采关键字进行搜索",

  // WORD
  WORD_REDMO: "红娘：",
  WORD_FRANZ: "门店：",
  WORD_PTNR: "商家：",
  WORD_START_TIME: "开始时间：",
  WORD_END_TIME: "结束时间：",
  WORD_TOTAL_BENEFIT: "收益总额(元)：",
  WORD_SELECT_QUERY_REDMO: "请选择查询的红娘",
  WORD_SELECT_QUERY_FRANZ: "请选择查询的门店",
  WORD_SELECT_QUERY_PTNR: "请选择查询的商家",
  WORD_SELECT_START_TIME: "请选择开始时间",
  WORD_SELECT_END_TIME: "请选择结束时间",
  WORD_PROMOTE_BENEFIT_RECORDS: "推广收益记录",
  WORD_DEV_REDMO: "发展红娘",
  WORD_DEV_USER: "发展用户",
  WORD_HANDLE_TASK: "的任务执行列表",
  WORD_ARRANGE_TASK: "的任务布置列表",
  WORD_TASK_STATUS: "任务状态：",
  WORD_SELECT_TASK_STATUS: "请选择任务的状态",
  WORD_INPUT_CORP_KEYNAME: "请根据企业名称关键字进行搜索",
  WORD_ADVERTISEMENT_STATUS_ONLINE: "已上架",
  WORD_ADVERTISEMENT_STATUS_DOWNLINE: "已下架",
  WORD_PLACEHOLDER_SEARCH_USER: "请根据用户手机号进行搜索",
  WORD_CCPAGE_INPUT_TITLE: "请选择长页标题",
  WORD_CCPAGE_INPUT_TYPE: "请选择长页类型",
  WORD_ADMIN_SUBMIT_AGAIN: "此账号信息已存在,再次提交可注册同号码子账号",
  // 标题
  WORD_TITLE_ROUTE_BTN_INSERT: "添加页面按钮",
  WORD_TITLE_ROUTE_BTN_UPDATE: "编辑页面按钮",
  WORD_TITLE_RIGHT_BUTTON_INSERT: "添加权限按钮",
  WORD_TITLE_RIGHT_BUTTON_UPDATE: "编辑权限按钮",
  WORD_TITLE_TABLE_RIGHT_BUTTON: "按钮列表",
  WORD_TITLE_FEEDBACK_RECORD: "反馈记录",
  WORD_TITLE_MESSAGE_RECORD: "消息记录",
  WORD_TITLE_CONTRACT_RECORD: "合同记录",
  //
  WORD_DELETE_TIPS: "是否确认删除该条记录?",
  WORD_PUTUP_ADV: "是否确认上架广告？",
  WORD_PUTDOWN_ADV: "是否确认下架广告？",

  // BTN
  MY_ARRANGE_BTN: "我的安排",
  MY_HANDLE_BTN: "我的处理",
  BTN_INSERT_ADVERTISEMENT: "新增广告",
  BTN_SET_ONLINE: "上架",
  BTN_SET_DOWNLINE: "下架",
  BTN_BIND_OWNER: "绑定所有者",
  BTN_ROUTE_BTN_RIGHT: "按钮管理",
  BTN_ROUTE_BTN_INSERT: "新增页面按钮",
  BTN_BTN_MGMT_INSERT: "新增按钮",
  BTN_ASSIGN_ROUTE_RIGHT: "路由权限",
  BTN_ASSIGN_BUTTON_RIGHT: "按钮权限",
  BTN_ROLE_BTN_INSERT: "新增角色按钮",
  BTN_USER_ASSIGN_ROLE: "分配角色",
  BTN_FRANZ_OPEN_TILE: "门店时间",
  BTN_FRANZ_LABEL: "门店标签",
  BTN_APPLY_PAGE: "去申请",
  BTN_FORM_CONFIRM: "确 认",
  BTN_FORM_CANCEL: "取 消",
  BTN_CCPAGE_TEXT: "文本",
  BTN_CCPAGE_IMAGE: "图片",
  BTN_CCPAGE_VIDEO: "视频",
  BTN_CCPAGE_SWIPER: "轮播图",
  BTN_REDMO_REGISTER: "红娘代注册",
  BTN_CONTRACT_INSERT: "新增合同",
  BTN_INSERT_DESIGN: "添加设计",
  BTN_INSERT_POLICY: "新增政策咨讯",
  BTN_INSERT_HEALTH: "新增养身建议",
  BTN_INSERT_GOODS: "新增心选好物",
  BTN_INSERT_ICON: "新增图标",
  BTN_PTNR_EXTRAINFO: "配套服务",
  BTN_PTNR_ONLINE: "上架",
  BTN_PTNR_OFFLINE: "下架",
  BTN_ADD_ITEM: "添加",
  BTN_DEL_ITEM: "删除",
  BTN_INSERT_ACTVT_FRIEND: "新增老友研学季",
  BTN_INSERT_ACTVT_TRAVEL: "新增欣颐旅居",
  BTN_INSERT_ACTVT_OLDER: "新增居家助老",
  BTN_INSERT_ACTVT_WELFARE: "新增精选福利",
  BTN_INSERT_RULE: "添加配套",

  // LOADING
  LOADING_SUBMIT: "提交中...",
  LOADING_SET: "设置中...",
  LOADING_DELETE: "删除中...",
  LOADING_REVIEW: "审核中...",
  LOADING_UPDATE: "修改中",
  LOADING_PASS: "同意中...",
  LOADING_REJECT: "拒绝中...",
  LOADING_REQUEST: "加载中...",
  LOADING_UPLOAD_IMAGE: "图片上传中...",
  LOADING_UPLOAD_VIDEO: "视频上传中...",

  // TOAST
  TOAST_SUBMIT_SUCCESS: "提交成功",
  LOADING_SUBMIT: "提交失败",
  TOAST_REGISTER_SUCCESS: "注册成功",
  TOAST_SET_SUCCESS: "设置成功",
  TOAST_DELETE_SUCCESS: "删除成功",
  TOAST_REVIEW_SUCCESS: "审核成功",
  TOAST_UPDATE_SUCCESS: "修改成功",
  TPAST_PASS_SUCCESS: "同意成功",
  TPAST_REJECT_SUCCESS: "拒绝成功",
  TOAST_DONT_DELETE_LASTONE: "已经是最后一个了",
  TOAST_UPLOAD_IMAGE_SUCCESS: "图片上传成功",
  TOAST_UPLOAD_IMAGE_FAIL: "图片上传失败",
  TOAST_UPLOAD_VIDEO_SUCCESS: "视频上传成功",
  TOAST_UPLOAD_VIDEO_FAIL: "视频上传失败",

  // PERCH
  PERCH_REVIEW_STATUS: "审批状态：",
  PERCH_REVIEW_STATUS_PLACEHOLDER: "请选择审批状态",
  PERCH_UPPER_PARTNER: "加盟商：",
  PERCH_UPPER_PARTNER_PLACEHOLDER: "请选择加盟商",
  PERCH_SEARCH_PLACEHOLDER_FRANZ: "请输入加盟店关键字",
  PERCH_SEARCH_PLACEHOLDER_PTNR: "请输入加盟商关键字",
  PERCH_CCPAGE_TYPE: "图文类型：",
  PERCH_CCPAGE_TYPE_PLACEHOLDER: "请选择图文类型",
  PERCH_ADV_STATUS: "广告状态：",
  PERCH_ADV_STATUS_PLACEHOLDER: "请选择广告状态",
  PERCH_CVC_SEARCH_PLACEHOLDER: "请输入卡券名称关键字搜索卡券",
  PERCH_CERT_STATUS: "实名状态：",
  PERCH_CERT_STATUS_PLACEHOLDER: "请选择实名状态",
  PERCH_CERT_TIMESORT: "排序方式：",
  PERCH_CERT_TIMESORT_PLACEHOLDER: "请选择排序方式",
  PERCH_MESSAGE_TYPE: "消息类型：",
  PERCH_MESSAGE_TYPE_PLACEHOLDER: "消息类型：",
  PERCH_CONTRACT_SEARCH_PLACEHOLDER: "请根据合同名称关键字搜索",
  PERCH_DESIGN_TYPE: "设计类型：",
  PERCH_DESIGN_TYPE_PLACEHOLDER: "请选择设计类型",
  PERCH_ICON_TYPE: "图标类型：",
  PERCH_ICON_TYPE_PLACEHOLDER: "请选择图标类型",

  PERCH_PTNR_PROPERTY: "机构属性：",
  PERCH_PTNR_PROPERTY_PLACEHOLDER: "请选择机构属性",

  PERCH_STAFF_TYPE: "身份类型：",
  PERCH_STAFF_TYPE_PLACEHOLDER: "请选择身份类型",
  PERCH_STAFF_ISREVIEW: "是否审批：",
  PERCH_STAFF_ISREVIEW_PLACEHOLDER: "请选择是否审批",

  PERCH_RULE_TYPE: "配套类型：",
  PERCH_RULE_TYPE_PLACEHOLDER: "请选择配套类型",

  PERCH_PTNR_STATUS: "机构状态：",
  PERCH_PTNR_STATUS_PLACEHOLDER: "请选择机构状态",

  PERCH_PTNR_NAME: "选择机构：",
  PERCH_PTNR_NAME_PLACEHOLDER: "请选择机构",

  PERCH_COMMENT_TYPE: "业务类型：",
  PERCH_COMMENT_TYPE_PLACEHOLDER: "请选择业务类型",
};
