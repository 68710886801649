/**
 * @author: Jok
 * @date: 2022-10-27 09:23
 * @explain: common config file,include base url.
 */
/**
 * @updateAuthor: Jok
 * @updateDate: 2023-01-30 17:00
 * @updateExplain: add creator infomation and some explain text.
 */

let BASE_URL = "";
if (process.env.NODE_ENV === "development") {
  // BASE_URL = "http://192.168.3.4:9098/baisea-ee-xxys-api/" //叶 tp59fc
  // BASE_URL = "http://121.40.159.117:9098/baisea-ee-xxys-api/"; // xxy服务器
  BASE_URL = "https://www.happyxinxin1.com/baisea-ee-xxys-api/";
} else {
  BASE_URL = "https://www.happyxinxin1.com/baisea-ee-xxys-api/"; // xxy域名
}
export default BASE_URL;
