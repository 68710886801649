/**
 * @author: Jok
 * @date: 2023-03-31 10:50
 * @explain: a custom AJAX.
 */

import axios from "./interceptor";
import BASE_URL from "@/config";
import { Message } from 'element-ui';

axios.ajax = function ajax(url, method, data, params, config) {
  if (method == "get") {
    return new Promise((resolve, reject) => {
      axios({
        url: BASE_URL + url,
        method: method,
        params: data,
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        ...config,
      })
        .then((res) => {
          if (res.status == 401) {
            localStorage.clear();
          }
          if (res.status == 200 && res.data.code == 200) {
            resolve(res.data);
          } else {
            reject(res.data);
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            Message.error("登录已过期，请重新登录");
            localStorage.clear();
          }
          reject(err);
        });
    });
  } else {
    if (method === "post" && params) {
      url += Object.keys(params).reduce((sum, key, i) => {
        if (i === 0) sum += `${key}=${encodeURIComponent(params[key])}`;
        else sum += `&${key}=${encodeURIComponent(params[key])}`;
        return sum;
      }, "?");
    }
    return new Promise((resolve, reject) => {
      axios({
        url: BASE_URL + url,
        method: method,
        data: data,
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        ...config,
      })
        .then((res) => {
          if (res.status == 401) {
            localStorage.clear();
          }
          // console.log(res)
          if (res.status == 200 && res.data.code == 200) {
            resolve(res.data);
          } else {
            reject(res.data);
          }
        })
        .catch((err) => {
          if (err.response.status == 401) {
            Message.error("登录已过期，请重新登录");
            localStorage.clear();
          }
          reject(err);
        });
    });
  }
};

export default axios;
