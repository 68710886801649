export default {
  // 每个页面的额外配置
  PAGE_CONFIG: {
    // 机构入驻
    "/partnerReview/partnerInsert": {
      // 自动保存
      autoSave: true,
      // 离开页面提示
      leaveWarn: true,
    },
    // 老友研学季
    "/actvtPublish/actvtInsert": {
      // 自动保存
      autoSave: true,
      // 离开页面提示
      leaveWarn: true,
    },
    // 欣颐旅居
    "/actvtPublish/actvtInsertTravel": {
      // 自动保存
      autoSave: true,
      // 离开页面提示
      leaveWarn: true,
    },
    // 居家助老
    "/actvtPublish/actvtInsertOlder": {
      // 自动保存
      autoSave: true,
      // 离开页面提示
      leaveWarn: true,
    },
    // 精选福利
    "/actvtPublish/actvtInsertWelfare": {
      // 自动保存
      autoSave: true,
      // 离开页面提示
      leaveWarn: true,
    },
    // 人物风采
    "/characterStyle/characterInsert": {
      // 自动保存
      autoSave: true,
      // 离开页面提示
      leaveWarn: true,
    },
    // 欣颐课堂
    "/xinyiLesson/lessonInsert": {
      // 自动保存
      autoSave: true,
      // 离开页面提示
      leaveWarn: true,
    },
    // 政策咨询
    "/policy/policyInsert": {
      // 自动保存
      autoSave: true,
      // 离开页面提示
      leaveWarn: true,
    },
    // 养身建议
    "/health/healthInsert": {
      // 自动保存
      autoSave: true,
      // 离开页面提示
      leaveWarn: true,
    },
    // 心选好物
    "/goods/goodsInsert": {
      // 自动保存
      autoSave: true,
      // 离开页面提示
      leaveWarn: true,
    },
    // 心选好物
    "/advertisementConf/advertisementInsert": {
      // 自动保存
      autoSave: true,
      // 离开页面提示
      leaveWarn: true,
    },
  },

  SYSTEM_INFO: {
    APP_NAME: "欣欣颐",
    APP_NAME_EN: "XINXIN_YI",
    APP_ID: "wx7cc09d0a113a57bc",
    VERSION_NUMBER: "1.0.0", // 版本
  },
};
