/**
 * @author: Jok
 * @date: 2022-10-27 09:23
 * @explain: VUE main js file.
 */
/**
 * @updateAuthor: Jok
 * @updateDate: 2023-01-30 17:00
 * @updateExplain: add creator infomation and some explain text.
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "./api/index";
import dayjs from "dayjs";
import variateUnify from "@/config/variateUnify";
import { setLocalStorage, getLocalStorage } from "@/uitls/commonFunction";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

// 富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)
// 富文本图片大小
// import imageResize  from 'quill-image-resize-module' // 调整大小组件。
// import { ImageDrop } from 'quill-image-drop-module'; // 拖动加载图片组件。
// Quill.register('modules/imageResize', imageResize );
// Quill.register('modules/imageDrop', ImageDrop);


Vue.use(ElementUI);

Vue.config.productionTip = false;
Vue.prototype.$vu = variateUnify;
Vue.prototype.$api = api;
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$setLocalStorage = setLocalStorage;
Vue.prototype.$getLocalStorage = getLocalStorage;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
