/**
 * @author: Jok
 * @date: 2022/10/27 09:23
 * @explain: a common js file which include all backend interface.
 * @updateBy:Jok-2023/01/30-add creator infomation and some explain text.
 * @updateBy:Jok-2023/06/27-代注册功能接口.
 */
import axios from "@/service/index";
import BASE_URL from "../config/index";

let api = {};

// 多文件上传
api.upload = BASE_URL + "upload";

// 文件上传 水印
api.uploadWatermark = BASE_URL + "uploadWatermark"; // 文件上传 水印
api.uploadWatermarkCut = "uploadWatermark"; // 文件上传 水印

// 批量删除文件
api.removeBatchFile = (data) => {
  return axios.post("removeBatchFile", data);
};

//账户登录
api.accountLogin = (data, params) => {
  return axios.post("Authentication/accountLogin", data, params);
};
//获取账户信息
api.getAccountInfo = () => {
  return axios.get("beUserAccounts/info");
};
//账户退出登录
api.accountLogOut = (data, params) => {
  return axios.post("Authentication/accountLogOut", data, params);
};
//查询账户列表
api.accountsSelect = (params) => {
  return axios.get("beUserAccounts/select", params);
};
// 添加账号信息
api.accountsInsert = (data) => {
  return axios.post("beUserAccounts/insert", data);
};
//删除账户
api.accountsRemove = (data, params) => {
  return axios.post("beUserAccounts/remove", data, params);
};
// 修改账号信息
api.AccountsUpdate = (data) => {
  return axios.post("beUserAccounts/update", data);
};

// 查询角色信息列表
api.bePlatRolesSelect = (params) => {
  return axios.get("bePlatRoles/select", params);
};
// 查询角色信息列表
api.bePlatRolesSelectPost = (data) => {
  return axios.post("bePlatRoles/selectPost", data);
};
// 添加角色信息
api.bePlatRolesInsert = (data) => {
  return axios.post("bePlatRoles/insert", data);
};
// 修改角色信息
api.bePlatRolesUpdate = (data) => {
  return axios.post("bePlatRoles/update", data);
};
// 删除角色信息
api.bePlatRolesRemove = (data, params) => {
  return axios.post("bePlatRoles/remove", data, params);
};

// 查询路由信息列表
api.beOperRightsSelect = (params) => {
  return axios.get("beOperRights/select", params);
};
// 查询路由信息详细
api.beOperRightsDetail = (params) => {
  return axios.get("beOperRights/detail", params);
};
// 添加路由信息列表
api.beOperRightsInsert = (data) => {
  return axios.post("beOperRights/insert", data);
};
// 修改路由信息列表
api.beOperRightsUpdate = (data) => {
  return axios.post("beOperRights/update", data);
};
// 删除路由信息列表
api.beOperRightsRemove = (data, params) => {
  return axios.post("beOperRights/remove", data, params);
};

// 查询角色路由信息列表
api.beRoleRightsSelect = (params) => {
  return axios.get("beRoleRights/select", params);
};
// 批量添加角色路由信息
api.beRoleRightsBatchInsert = (data, params) => {
  return axios.post("beRoleRights/batchInsert", data, params);
};
// 批量删除角色路由信息
api.beRoleRightsBatchRemove = (data, params) => {
  return axios.post("beRoleRights/batchRemove", data, params);
};

// 查询账号角色信息列表
api.beAccntRolesSelect = (params) => {
  return axios.get("beAccntRoles/select", params);
};
// 查询账号角色详细信息
api.beAccntRolesDetailed = (params) => {
  return axios.get("beAccntRoles/detailed", params);
};
// 添加账号角色信息
api.beAccntRolesInsert = (data, params) => {
  return axios.post("beAccntRoles/insert", data, params);
};
// 修改账号角色信息
api.beAccntRolesUpdate = (data, params) => {
  return axios.post("beAccntRoles/update", data, params);
};
// 删除账号角色信息
api.beAccntRolesRemove = (data, params) => {
  return axios.post("beAccntRoles/remove", data, params);
};

// 查询活动列表
api.tActivitiesSelect = (params) => {
  return axios.get("tActivities/select", params);
};

// 添加活动信息
api.tActivitiesInsert = (data) => {
  return axios.post("tActivities/insert", data);
};

// 修改活动信息
api.tActivitiesUpdate = (data) => {
  return axios.post("tActivities/update", data);
};

// 审批发布活动信息
api.tActivitiesReview = (data, params) => {
  return axios.post("tActivities/review", data, params);
};

// 查询活动类别信息树
api.tActvtTypesSelectTree = (params) => {
  return axios.get("tActvtTypes/selectTree", params);
};

// 获取加盟商列表
api.partnerListQuery = (data) => {
  return axios.post("tPartners/partnerListQuery", data);
};
// 获取加盟商详细信息
api.tPartnersDetailed = (params) => {
  return axios.get("tPartners/detailed", params);
};
// 修改加盟商信息
api.tPartnersUpdate = (data) => {
  return axios.post("tPartners/update", data);
};
// 删除加盟商信息
api.tPartnersRemove = (data) => {
  return axios.post("tPartners/remove", data);
};

//获取加盟商信息列表
api.getPtnrApplyList = (params) => {
  return axios.get("tPtnrApply/getPtnrApplyList", params);
};
//发起加盟商注册请求
api.signUpPntrApply = (data) => {
  return axios.post("tPtnrApply/signUpPntrApply", data);
};
//通过一个加盟商注册
api.passPtnrApply = (data, params) => {
  return axios.post("tPtnrApply/passPtnrApply", data, params);
};
//拒绝一个加盟商注册
api.rejectPtnrApply = (data, params) => {
  return axios.post("tPtnrApply/rejectPtnrApply", data, params);
};

//获取加盟商级别定义列表
api.getRolelvList = (data) => {
  return axios.post("tConfRolelv/getRolelvList", data);
};

//获取待审核门店列表
api.getFranzApplyList = (params) => {
  return axios.get("tFranzApply/getFranzApplyList", params);
};
//发起门店申请
api.insertFranzApply = (data) => {
  return axios.post("tFranzApply/insertFranzApply", data);
};
//管理员通过加盟店申请
api.accessFranzApply = (data, params) => {
  return axios.post("tFranzApply/accessFranzApply", data, params);
};
//管理员拒绝加盟店申请
api.rejectFranzApply = (data, params) => {
  return axios.post("tFranzApply/rejectFranzApply", data, params);
};

// 获取加盟商/加盟店工作时间
api.tFranzBizhoursBizHourListQuery = (data, params) => {
  return axios.post("tFranzBizhours/bizHourListQuery", data, params);
};
// 添加工作时间
api.tFranzBizhoursInsert = (data) => {
  return axios.post("tFranzBizhours/bizHourInsert", data);
};
// 更新工作时间
api.tFranzBizhoursBizHourUpdate = (data) => {
  return axios.post("tFranzBizhours/bizHourUpdate", data);
};

// 查询店铺标签信息
api.tFranzLabelsSelect = (params) => {
  return axios.get("tFranzLabels/select", params);
};
// 批量添加店铺标签信息
api.tFranzLabelsBatchInsert = (data) => {
  return axios.post("tFranzLabels/batchInsert", data);
};
// 批量修改店铺标签信息
api.tFranzLabelsBatchUpdate = (data) => {
  return axios.post("tFranzLabels/batchUpdate", data);
};
// 批量删除店铺标签信息
api.tFranzLabelsBatchRemove = (data) => {
  return axios.post("tFranzLabels/batchRemove", data);
};

// 查询规则配置信息列表
api.tConfRulesSelect = (params) => {
  return axios.get("tConfRules/tConfRulesSelect", params);
};
// 查询规则配置信息详情
api.tConfRulesDetailed = (params) => {
  return axios.get("tConfRules/detailed", params);
};
// 添加规则配置信息
api.tConfRulesInsert = (data) => {
  return axios.post("tConfRules/tConfRulesInsert", data);
};
// 修改配置规则
api.tConfRulesUpdate = (data) => {
  return axios.post("tConfRules/tConfRulesUpdate", data);
};
// 删除配置规则
api.tConfRulesRemove = (data) => {
  return axios.post("tConfRules/remove", data);
};

// 修改配置规则
api.tConfRulesUpdate = (data) => {
  return axios.post("tConfRules/tConfRulesUpdate", data);
};

// 查询用户信息列表
api.tUsersSelect = (params) => {
  return axios.get("tUsers/select", params);
};
// 查询用户详细信息
api.tUsersDetailed = (params) => {
  return axios.get("tUsers/detailed", params);
};

// 导出推广员信息
api.tStaffsExport = (params) => {
  return axios.get("tStaffs/tStaffsExport", params);
};
api.tStaffsExportUrl = BASE_URL + "tStaffs/tStaffsExport";
// 查询推广员信息
api.tStaffsSelect = (params) => {
  return axios.get("tStaffs/tStaffsSelect", params);
};
// 查询推广员信息详细
api.tStaffsDetail = (params) => {
  return axios.get("tStaffs/detail", params);
};
// 添加推广员信息
api.tStaffsInsert = (data, params) => {
  return axios.post("tStaffs/tStaffsInsert", data, params);
};
// 修改推广员信息
api.tStaffsUpdate = (data, params) => {
  return axios.post("tStaffs/tStaffsUpdate", data, params);
};
// 删除推广员信息
api.tStaffsDelete = (data, params) => {
  return axios.post("tStaffs/tStaffsDelete", data, params);
};

// 审批发布用户入驻信息
api.tStaffsReview = (data) => {
  return axios.post("tStaffs/review", data);
};

// 查询加盟商组织信息列表
api.tPtnrOrgstrusSelect = (params) => {
  return axios.get("tPtnrOrgstrus/tPtnrOrgstrusSelect", params);
};
// 添加加盟商组织信息列表
api.tPtnrOrgstrusInsert = (data) => {
  return axios.post("tPtnrOrgstrus/tPtnrOrgstrusInsert", data);
};
// 修改加盟商组织信息列表
api.tPtnrOrgstrusUpdate = (data) => {
  return axios.post("tPtnrOrgstrus/tPtnrOrgstrusUpdate", data);
};
// 删除加盟商组织信息列表
api.tPtnrOrgstrusDelete = (data) => {
  return axios.post("tPtnrOrgstrus/tPtnrOrgstrusDelete", data);
};

/** 卡券 */
// 加盟店获取卡券列表
api.tConfCvcmgmtSleect = (params) => {
  return axios.get("tConfCvcmgmt/sleect", params);
};
// 添加卡卷信息
api.tConfCvcmgmtInsert = (data) => {
  return axios.post("tConfCvcmgmt/insert", data);
};
// 根据卡券id获取卡券详细信息
api.getConfCvcmgmtInfoByCvcId = (data, params) => {
  return axios.post("tConfCvcmgmt/getConfCvcmgmtInfoByCvcId", data, params);
};
// 更新卡券信息
api.updateConfCvcmgmtByCvcId = (data) => {
  return axios.post("tConfCvcmgmt/updateConfCvcmgmtByCvcId", data);
};
// 根据卡券id删除对应卡券
api.deleteConfCvcmgmtByCvcId = (data, params) => {
  return axios.post("tConfCvcmgmt/deleteConfCvcmgmtByCvcId", data, params);
};

// 查询用户权限信息设置
api.tAddinfoSettingSelect = (params) => {
  return axios.get("tAddinfoSetting/select", params);
};
// 修改用户详细信息权限设置
api.tAddinfoSettingUpdate = (data) => {
  return axios.post("tAddinfoSetting/update", data);
};

// 查询权限设置
api.tAddinfoSettingDeatiled = (params) => {
  return axios.get("tAddinfoSetting/deatiled", params);
};
// 获取教育背景
api.bkgdEduListQuery = (params) => {
  return axios.get("tBkgdEdu/bkgdEduListQuery", params);
};
// 获取工作背景
api.bkgdWorkListQuery = (params) => {
  return axios.get("tBkgdWork/bkgdWorkListQuery", params);
};

// 查询用户信息列表
api.tUserAddinfoSelect = (params) => {
  return axios.get("tUserAddinfo/select", params);
};
// 获取用户详细信息
api.tUserAddinfoDetailed = (params) => {
  return axios.get("tUserAddinfo/detailed", params);
};
// 修改用户详细信息
api.tUserAddinfoUpdate = (data) => {
  return axios.post("tUserAddinfo/update", data);
};

// 查询图集相册图片
api.tAlbumsSelect = (params) => {
  return axios.get("tAlbums/tAlbumsSelect", params);
};
//获取举报列表
api.tUserReportingsSelect = (params) => {
  return axios.get("tUserReportings/select", params);
};
//查询举报详情
api.tUserReportingsDetailed = (params) => {
  return axios.get("tUserReportings/detailed", params);
};
//审核举报信息
api.tUserReportingsUpdate = (params) => {
  return axios.post("tUserReportings/update", params);
};
//删除举报信息
api.tUserReportingsRemove = (data, params) => {
  return axios.post("tUserReportings/remove", data, params);
};

api.tUsersUpdate = (data) => {
  return axios.post("tUsers/update", data);
};

//添加用户反馈信息
api.tUserFeedbacksInsert = (data) => {
  return axios.post("tUserFeedbacks/insert", data);
};
// 查询反馈信息
api.tUserFeedbacksSelect = (params) => {
  return axios.get("tUserFeedbacks/select", params);
};
// 查询反馈信息详细
api.tUserFeedbacksDetailed = (params) => {
  return axios.get("tUserFeedbacks/detailed", params);
};
//删除反馈信息
api.tUserFeedbacksRemove = (data, params) => {
  return axios.post("tUserFeedbacks/remove", data, params);
};

// 添加站内消息
api.tMsgInternalInsert = (data) => {
  return axios.post("tMsgInternal/insert", data);
};
// 删除一条消息
api.tMsgInternalDelete = (data, params) => {
  return axios.post("tMsgInternal/msgInternalDelete", data, params);
};
// 获取站内发送的消息列表
api.tMsgInternallQuerySend = (params) => {
  return axios.get("tMsgInternal/msgInternalQuerySend", params);
};

// 查询推广收益-红娘
api.intmPostsvcProfitsSelect = (params) => {
  return axios.get("intmPostsvcProfits/intmPostsvcProfitsSelect", params);
};
// 查询推广收益-总额
api.intmPostsvcProfitsStatistics = (params) => {
  return axios.get("intmPostsvcProfits/intmPostsvcProfitsStatistics", params);
};

// 查询人员角色信息列表
api.staffRolesListQuery = (data, params) => {
  return axios.post("tStaffRoles/staffRolesListQuery", data, params);
};

// 添加合同信息
api.tContractsInsert = (data) => {
  return axios.post("tContracts/insert", data);
};
// 删除合同信息
api.tContractsRemove = (data, params) => {
  return axios.post("tContracts/remove", data, params);
};
// 修改合同信息
api.tContractsUpdate = (data) => {
  return axios.post("tContracts/update", data);
};
// 查询合同信息
api.tContractsSelect = (params) => {
  return axios.get("tContracts/select", params);
};
// 查询合同信息详细
api.tContractsDetailed = (params) => {
  return axios.get("tContracts/detailed", params);
};

// 查询任务审批人员配置列表
api.t5WkstepExecutorSelect = (params) => {
  return axios.get("t5WkstepExecutor/t5WkstepExecutorSelect", params);
};
// 提交人员任务申请
api.taskAssignSave = (data) => {
  return axios.post("/taskAssign/save", data);
};
// 获取人员任务列表
api.getTaskList = (params) => {
  return axios.get("taskAssign/getTaskList", params);
};
// 接受/拒绝任务
api.acceptTaskAssign = (data) => {
  return axios.post("taskAssign/acceptTaskAssign", data);
};
// 获取人员任务详情
api.getTaskDetail = (params) => {
  return axios.get("taskAssign/getTaskDetail", params);
};
// 保存抄送审核
api.saveCcList = (data) => {
  return axios.post("taskAssign/saveCcList", data);
};
// 提交反馈/办结申请
api.saveFeedback = (data) => {
  return axios.post("taskAssign/saveFeedback", data);
};
// 同意/驳回任务
api.completeTask = (data) => {
  return axios.post("taskAssign/completeTask", data);
};

// 添加活动长页信息
api.tActvtCcpagesInsert = (data) => {
  return axios.post("tActvtCcpages/insert", data);
};
// 删除活动长页信息
api.tActvtCcpagesRemove = (data, params) => {
  return axios.post("tActvtCcpages/remove", data, params);
};
// 修改活动长页
api.tActvtCcpagesUpdate = (data) => {
  return axios.post("tActvtCcpages/update", data);
};
// 查询活动长页信息列表
api.tActvtCcpagesSelect = (params) => {
  return axios.get("tActvtCcpages/select", params);
};
// 查询活动长页信息详细
api.tActvtCcpagesDetailed = (params) => {
  return axios.get("tActvtCcpages/detailed", params);
};

//添加文字标注信息
api.mksetMarksInsert = (params) => {
  return axios.post("t0MksetMarks/mksetMarksInsert", params);
};
//添加图册信息
api.tAlbumsInsert = (params) => {
  return axios.post("tAlbums/tAlbumsInsert", params);
};
//添加视频信息
api.vvsetVideosInsert = (params) => {
  return axios.post("t0VvsetVideos/vvsetVideosInsert", params);
};
// 添加长页详细信息
api.tCcpageDetailsInsert = (data) => {
  return axios.post("tCcpageDetails/insert", data);
};
// 删除长页详细信息
api.tCcpageDetailsRemove = (params) => {
  return axios.post("tCcpageDetails/remove", {}, params);
};
// 修改长页详细信息
api.tCcpageDetailsUpdate = (data) => {
  return axios.post("tCcpageDetails/update", data);
};
// 查询长页详细信息
api.tCcpageDetailsSelect = (params) => {
  return axios.get("tCcpageDetails/select", params);
};
// 查询长页详细信息详细
api.tCcpageDetailsDetailed = (params) => {
  return axios.get("tCcpageDetails/detailed", params);
};

// 查询文字标注信息
api.t0MksetMarksSelect = (params) => {
  return axios.get("t0MksetMarks/mksetMarksSelect", params);
};
// 查询视频信息
api.t0VvsetVideosSelect = (params) => {
  return axios.get("t0VvsetVideos/vvsetVideosSelect", params);
};

// 查询套餐信息
api.tPackagesSelect = (params) => {
  return axios.get("tPackages/select", params);
};
// 添加套餐信息
api.tPackagesSave = (data) => {
  return axios.post("tPackages/save", data);
};
// 删除套餐信息
api.tPackagesRemove = (data) => {
  return axios.post("tPackages/remove", data);
};
// 修改套餐信息
api.tPackagesUpdate = (data) => {
  return axios.post("tPackages/update", data);
};

// 查询服务信息
api.tServesSelect = (params) => {
  return axios.get("tServes/select", params);
};
// 添加服务信息
api.tServesSave = (data) => {
  return axios.post("tServes/save", data);
};
// 删除服务信息
api.tServesRemove = (data) => {
  return axios.post("tServes/remove", data);
};
// 修改服务信息
api.tServesUpdate = (data) => {
  return axios.post("tServes/update", data);
};

// 查询服务权限信息
api.tServePermissionsSelect = (params) => {
  return axios.get("tServePermissions/select", params);
};
// 添加服务权限信息
api.tServePermissionsSave = (data) => {
  return axios.post("tServePermissions/save", data);
};
// 删除服务权限信息
api.tServePermissionsRemove = (data) => {
  return axios.post("tServePermissions/remove", data);
};
// 修改服务权限信息
api.tServePermissionsUpdate = (data) => {
  return axios.post("tServePermissions/update", data);
};

// 红娘代注册列表
api.tUsersSelectByRcmdrId = (params) => {
  return axios.get("tUsers/selectByRcmdrId", params);
};

// 提现记录列表
api.walletsCashOutListByAdmin = (params) => {
  return axios.get("wallets/cashOutListByAdmin", params);
};
// 余额提现（实际提现操作）
api.walletsCashOutOperate = (params) => {
  return axios.get("wallets/cashOutOperate", params);
};
// 拒绝提现
api.walletsDeclineWithdrawals = (data) => {
  return axios.post("wallets/declineWithdrawals", data);
};

// 查询活动详情
api.tActivitiesDetailed = (params) => {
  return axios.get("tActivities/detailed", params);
};

// 删除活动信息
api.tActivitiesRemove = (data) => {
  return axios.post("tActivities/remove", data);
};

// 添加加盟商长页信息
api.tPtnrCcpagesSave = (data) => {
  return axios.post("tPtnrCcpages/save", data);
};
// 查询用户加盟商长页信息
api.tPtnrCcpagesSelect = (params) => {
  return axios.get("tPtnrCcpages/select", params);
};

// 添加企业ccpage信息
api.tCorpCcpagesInsert = (data) => {
  return axios.post("tCorpCcpages/TCorpCcpagesInsert", data);
};
// 查询企业ccpage信息
api.tCorpCcpagesSelect = (params) => {
  return axios.get("tCorpCcpages/TCorpCcpagesSelect", params);
};

// 获取合作伙伴列表
api.tCorporationsListQuery = (params) => {
  return axios.get("tCorporations/corporationsListQuery", params);
};
// 添加一个合作伙伴
api.tCorporationsInsert = (data) => {
  return axios.post("tCorporations/corporationsInsert", data);
};
// 通过一个合作伙伴
api.tCorporationsPass = (data, params) => {
  return axios.post("tCorporations/corporationsPass", data, params);
};
// 拒绝一个合作伙伴
api.tCorporationsReject = (data, params) => {
  return axios.post("tCorporations/corporationsReject", data, params);
};
// 查询企业详细信息
api.tCorporationsSelectMinute = (params) => {
  return axios.get("tCorporations/tCorporationsSelectMinute", params);
};
// 修改企业信息
api.tCorporationsUpdate = (data) => {
  return axios.post("tCorporations/tCorporationsUpdate", data);
};
// 删除企业信息
api.tCorporationsRemove = (data) => {
  return axios.post("tCorporations/remove", data);
};

// 平台添加企业号
api.tUsersInsertBusiness = (data) => {
  return axios.post("tUsers/insertBusiness", data);
};
// 查询子账号
api.beUserAccountsSelectChildList = (params) => {
  return axios.get("beUserAccounts/selectChildList", params);
};

// 删除用户信息
api.tUsersRemove = (data, params) => {
  return axios.post("tUsers/remove", data, params);
};

// 查询账号详细信息
api.beUserAccountsDetailed = (params) => {
  return axios.get("beUserAccounts/detailed", params);
};

/** 企业应征 */

// 查询企业应征信息
api.tCorpMarriesSelect = (params) => {
  return axios.get("tCorpMarries/select", params);
};

/** 新卡券 */

// 添加卡卷信息
api.tConfCvcmgmtSave = (data) => {
  return axios.post("tConfCvcmgmt/save", data);
};
// 修改卡卷信息
api.tConfCvcmgmtUpdate = (data) => {
  return axios.post("tConfCvcmgmt/update", data);
};
// 删除卡卷信息
api.tConfCvcmgmtRemove = (data) => {
  return axios.post("tConfCvcmgmt/remove", data);
};
// 查询卡卷信息
api.tConfCvcmgmtSelect = (params) => {
  return axios.get("tConfCvcmgmt/select", params);
};
// 查询卡卷详细信息
api.tConfCvcmgmtDetailed = (params) => {
  return axios.get("tConfCvcmgmt/detailed", params);
};

// 查询广告信息列表
api.tAdvertisementsSelect = (params) => {
  return axios.get("tAdvertisements/select", params);
};
// 查询广告信息详细
api.tAdvertisementsDetailed = (params) => {
  return axios.get("tAdvertisements/detailed", params);
};
// 添加广告信息
api.tAdvertisementsSave = (data) => {
  return axios.post("tAdvertisements/save", data);
};
// 修改广告信息
api.tAdvertisementsUpdate = (data) => {
  return axios.post("tAdvertisements/update", data);
};
// 删除广告信息
api.tAdvertisementsRemove = (data) => {
  return axios.post("tAdvertisements/remove", data);
};

// 根据规则查询角色信息列表
api.bePlatRolesSelectByRoueLv = (data) => {
  return axios.post("bePlatRoles/selectByRoueLv", data);
};

// 查询按钮权限信息列表
api.beButnRightsSelect = (params) => {
  return axios.get("beButnRights/select", params);
};
// 添加按钮权限信息
api.beButnRightsSave = (data) => {
  return axios.post("beButnRights/insert", data);
};
// 删除按钮权限信息
api.beButnRightsRemove = (data) => {
  return axios.post("beButnRights/remove", data);
};
// 修改按钮权限信息
api.beButnRightsUpdate = (data) => {
  return axios.post("beButnRights/update", data);
};

// 查询路由按钮关系信息
api.beOperButnSelect = (params) => {
  return axios.get("beOperButn/select", params);
};
// 添加路由按钮关系信息
api.beOperButnSave = (data) => {
  return axios.post("beOperButn/save", data);
};
// 删除路由按钮关系信息
api.beOperButnRemove = (data) => {
  return axios.post("beOperButn/remove", data);
};
// 修改路由按钮关系信息
api.beOperButnUpdate = (data) => {
  return axios.post("beOperButn/update", data);
};

// 查询角色按钮信息
api.beRoleButnSelect = (params) => {
  return axios.get("beRoleButn/select", params);
};
// 批量添加角色按钮信息
api.beRoleButnBatchInsert = (data, params) => {
  return axios.post("beRoleButn/batchInsert", data, params);
};
// 批量删除角色按钮信息
api.beRoleButnBatchRemove = (data, params) => {
  return axios.post("beRoleButn/batchRemove", data, params);
};
// 修改角色按钮信息
api.beRoleButnUpdate = (data) => {
  return axios.post("beRoleButn/update", data);
};

// 查询用户角色信息列表
api.tUserRolesSelect = (params) => {
  return axios.get("tUserRoles/select", params);
};
// 添加用户角色信息
api.tUserRolesSave = (data) => {
  return axios.post("tUserRoles/save", data);
};
// 删除用户角色信息
api.tUserRolesRemove = (data) => {
  return axios.post("tUserRoles/remove", data);
};
// 修改用户角色信息
api.tUserRolesUpdate = (data) => {
  return axios.post("tUserRoles/update", data);
};

// 获取加盟店列表
api.tFranzstoresListQuery = (params) => {
  return axios.get("tFranzstores/franzstoresListQuery", params);
};
// 查询门店详情
api.tFranzstoresDetailed = (params) => {
  return axios.get("tFranzstores/detailed", params);
};

// 查询我的相册详情列表
api.tUserAlbumsDetail = (params) => {
  return axios.get("tUserAlbums/detailed", params);
};
// 用户添加图册
api.tUserAlbumsInsert = (data) => {
  return axios.post("tUserAlbums/insert", data);
};
// 修改相册信息
api.tUserAlbumsUpdate = (data) => {
  return axios.post("tUserAlbums/update", data);
};

// 用户注册
api.userRegister = (data) => {
  return axios.post("Authentication/userRegister", data);
};

// 获取用户详细信息
api.userDetailSelect = (params) => {
  return axios.get("tUserAddinfo/detailed", params);
};
// 添加用户详细信息
api.userDetailAdd = (data) => {
  return axios.post("tUserAddinfo/insert", data);
};
// 更新用户详细信息
api.userDetailUpdate = (data) => {
  return axios.post("tUserAddinfo/update", data);
};

// 更新用户信息
api.tUsersUpdate = (data) => {
  return axios.post("tUsers/update", data);
};

// openAI综合信息接口
api.synthesisOpenAiSave = (data) => {
  return axios.post("synthesis/openAiSave", data);
};

// 添加合同角色信息信息
api.tContractsSaveContractRole = (data) => {
  return axios.post("tContracts/saveContractRole", data);
};

/** 欣欣颐 */

// 查询成功案例信息列表
api.tSuccessStoriesSelect = (params) => {
  return axios.get("tSuccessStories/select", params);
};
// 查询成功案例信息列表详细
api.tSuccessStoriesDetailed = (params) => {
  return axios.get("tSuccessStories/detailed", params);
};
// 添加成功案例信息
api.tSuccessStoriesSave = (data) => {
  return axios.post("tSuccessStories/save", data);
};
// 删除成功案例信息
api.tSuccessStoriesRemove = (data) => {
  return axios.post("tSuccessStories/remove", data);
};
// 修改成功案例信息
api.tSuccessStoriesUpdate = (data) => {
  return axios.post("tSuccessStories/update", data);
};

// 查询政策信息列表
api.tPolicySelect = (params) => {
  return axios.get("tPolicy/select", params);
};
// 查询政策信息详细
api.tPolicyDetailed = (params) => {
  return axios.get("tPolicy/detailed", params);
};
// 添加政策信息
api.tPolicySave = (data) => {
  return axios.post("tPolicy/save", data);
};
// 删除政策信息
api.tPolicyRemove = (data) => {
  return axios.post("tPolicy/remove", data);
};
// 修改政策信息
api.tPolicyUpdate = (data) => {
  return axios.post("tPolicy/update", data);
};

// 查询课程标签信息详细
api.tCourseLabelDetailed = (params) => {
  return axios.get("tCourseLabel/detailed", params);
};

// 删除课程标签信息
api.tCourseLabelRemove = (data) => {
  return axios.post("tCourseLabel/remove", data);
};

// 添加课程标签信息
api.tCourseLabelSave = (data) => {
  return axios.post("tCourseLabel/save", data);
};

// 查询课程标签信息列表
api.tCourseLabelSelect = (params) => {
  return axios.get("tCourseLabel/select", params);
};

// 修改课程标签信息
api.tCourseLabelUpdate = (data) => {
  return axios.post("tCourseLabel/update", data);
};

// 查询课程信息详细
api.tCourseDetailed = (params) => {
  return axios.get("tCourse/detailed", params);
};

// 删除课程信息
api.tCourseRemove = (data) => {
  return axios.post("tCourse/remove", data);
};

// 添加课程信息
api.tCourseSave = (data) => {
  return axios.post("tCourse/save", data);
};

// 查询课程信息列表
api.tCourseSelect = (params) => {
  return axios.get("tCourse/select", params);
};

// 修改课程信息
api.tCourseUpdate = (data) => {
  return axios.post("tCourse/update", data);
};

// 查询人物风采标签信息详细
api.tMienLabelDetailed = (params) => {
  return axios.get("tMienLabel/detailed", params);
};

// 删除人物风采标签信息
api.tMienLabelRemove = (data) => {
  return axios.post("tMienLabel/remove", data);
};

// 添加人物风采标签信息
api.tMienLabelSave = (data) => {
  return axios.post("tMienLabel/save", data);
};

// 查询人物风采标签信息列表
api.tMienLabelSelect = (params) => {
  return axios.get("tMienLabel/select", params);
};

// 修改人物风采标签信息
api.tMienLabelUpdate = (data) => {
  return axios.post("tMienLabel/update", data);
};

// 查询人物风采信息详细
api.tMienDetailed = (params) => {
  return axios.get("tMien/detailed", params);
};

// 删除人物风采信息
api.tMienRemove = (data) => {
  return axios.post("tMien/remove", data);
};

// 添加人物风采信息
api.tMienSave = (data) => {
  return axios.post("tMien/save", data);
};

// 查询人物风采信息列表
api.tMienSelect = (params) => {
  return axios.get("tMien/select", params);
};

// 修改人物风采信息
api.tMienUpdate = (data) => {
  return axios.post("tMien/update", data);
};

// 查询人员养生信息列表
api.tStaffHealthSelect = (params) => {
  return axios.get("tStaffHealth/select", params);
};
// 查询人员养生信息详细
api.tStaffHealthDetailed = (params) => {
  return axios.get("tStaffHealth/detailed", params);
};
// 添加人员养生信息
api.tStaffHealthSave = (data) => {
  return axios.post("tStaffHealth/save", data);
};
// 修改人员养生信息
api.tStaffHealthUpdate = (data) => {
  return axios.post("tStaffHealth/update", data);
};
// 删除人员养生信息
api.tStaffHealthRemove = (data) => {
  return axios.post("tStaffHealth/remove", data);
};

// 查询商品信息列表
api.tGoodsSelect = (params) => {
  return axios.get("tGoods/tGoodsSelect", params);
};
// 查询商品详细信息
api.tGoodsDetailed = (params) => {
  return axios.get("tGoods/tGoodsSelectDetailed", params);
};
// 添加商品信息
api.tGoodsInsert = (data) => {
  return axios.post("tGoods/tGoodsInsert", data);
};
// 审核商品接口
api.tGoodsCheck = (data, params) => {
  return axios.post("tGoods/tGoodsCheck", data, params);
};
// 修改商品信息
api.tGoodsUpdate = (data) => {
  return axios.post("tGoods/update", data);
};
// 删除商品信息
api.tGoodsRemove = (data) => {
  return axios.post("tGoods/remove", data);
};

/** 2023/08/10 16:15 */

// 查询机构信息列表
api.thXxyProjdetailsSelect = (params) => {
  return axios.get("thXxyProjdetails/select", params);
};
// 查询机构信息详细
api.thXxyProjdetailsDetailed = (params) => {
  return axios.get("thXxyProjdetails/detailed", params);
};
// 添加机构信息
api.thXxyProjdetailsSave = (data) => {
  return axios.post("thXxyProjdetails/save", data);
};
// 修改机构信息
api.thXxyProjdetailsUpdate = (data) => {
  return axios.post("thXxyProjdetails/update", data);
};
// 删除机构信息
api.thXxyProjdetailsRemove = (data) => {
  return axios.post("thXxyProjdetails/remove", data);
};

// 查询类型信息列表
api.tConfXxytypeSelect = (params) => {
  return axios.get("tConfXxytype/select", params);
};
// 查询类型信息详细
api.tConfXxytypeDetailed = (params) => {
  return axios.get("tConfXxytype/detailed", params);
};
// 添加类型信息
api.tConfXxytypeSave = (data) => {
  return axios.post("tConfXxytype/save", data);
};
// 修改类型信息
api.tConfXxytypeUpdate = (data) => {
  return axios.post("tConfXxytype/update", data);
};
// 删除类型信息
api.tConfXxytypeRemove = (data) => {
  return axios.post("tConfXxytype/remove", data);
};

// 查询对象信息列表
api.tProjdXxylistSelect = (params) => {
  return axios.get("tProjdXxylist/select", params);
};
// 查询对象信息详细
api.tProjdXxylistDetailed = (params) => {
  return axios.get("tProjdXxylist/detailed", params);
};
// 添加对象信息
api.tProjdXxylistSave = (data) => {
  return axios.post("tProjdXxylist/save", data);
};
// 修改对象信息
api.tProjdXxylistUpdate = (data) => {
  return axios.post("tProjdXxylist/update", data);
};
// 删除对象信息
api.tProjdXxylistRemove = (data) => {
  return axios.post("tProjdXxylist/remove", data);
};

// 查询活动类别信息列表
api.tActvtTypesSelect = (params) => {
  return axios.get("tActvtTypes/select", params);
};
// 添加活动类别信息
api.tActvtTypesInsert = (data) => {
  return axios.post("tActvtTypes/insert", data);
};
// 修改活动类别信息
api.tActvtTypesUpdate = (data) => {
  return axios.post("tActvtTypes/update", data);
};
// 删除活动类别信息
api.tActvtTypesRemove = (data, params) => {
  return axios.post("tActvtTypes/remove", data, params);
};

/** @updateBy:Jok-2023/10/19-增加界面预约记录 */
// 查询商家用户信息列表
api.tPtnrMembersSelect = (params) => {
  return axios.get("tPtnrMembers/select", params);
};
// 修改商家用户信息
api.tPtnrMembersUpdate = (params) => {
  return axios.post("tPtnrMembers/update", params);
};
// 删除商家用户信息
api.tPtnrMembersRemove = (params) => {
  return axios.post("tPtnrMembers/remove", params);
};

// 查询评论信息列表
api.tCommentsSelect = (params) => {
  return axios.get("tComments/select", params);
};
// 修改评论信息
api.tCommentsUpdate = (data) => {
  return axios.post("tComments/update", data);
};
// 删除评论信息
api.tCommentsRemove = (data) => {
  return axios.post("tComments/remove", data);
};
// 获取用户访问小程序日留存
api.statisticsWx = (data) => {
  return axios.post("statistics/wx", data);
};
// 获取接口调用凭据
api.configSelectProof = (params) => {
  return axios.get("config/selectProof", params);
};
// 用户信息统计
api.statisticsUser = (data) => {
  return axios.post("statistics/user", data);
};

/** 名医预约 */
// 查询人员申请协助用户信息列表
api.tStaffApply4taSelect = (data) => {
  return axios.get("tStaffApply4ta/select", data);
};
// 删除人员申请协助信息
api.tStaffApply4taRemove = (data) => {
  return axios.post("tStaffApply4ta/remove", data);
};
// 修改人员申请协助信息
api.tStaffApply4taUpdate = (data) => {
  return axios.post("tStaffApply4ta/update", data);
};

export default api;
