/**
 * @author: samuel
 * @date: 2022-10-27 09:23
 * @explain: a custom AJAX wrapper.
 */
/**
 * @updateAuthor: Jok
 * @updateDate: 2023-01-30 17:00
 * @updateExplain: add creator infomation and some explain text.
 */
import axios from "./http";

export default {
  get: function (url, data, config) {
    return axios.ajax(url, "get", data, config);
  },
  post: function (url, data, params, config) {
    return axios.ajax(url, "post", data, params, config);
  },
};
