/**
 * @author: samuel
 * @date: 2022-10-27 09:23
 * @explain: a custom AJAX interceptor.
 */
/**
 * @updateAuthor: Jok
 * @updateDate: 2023-01-30 17:00
 * @updateExplain: add creator infomation and some explain text.
 */
import axios from 'axios';

axios.interceptors.request.use(
    config => {
        config.headers.userType = 'account'
        if(localStorage.getItem('authorization')){
            config.headers.Authorization = localStorage.getItem('authorization')
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

axios.interceptors.response.use(
    response => {
        if(response.headers.authorization){
            localStorage.setItem("authorization", response.headers.authorization)
        }
        return response;
    }
);
export default axios;